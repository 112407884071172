<template>
  <div class="charge-container">
    <ContentTitle>{{ tableTitle }}</ContentTitle>
    <Wfilter
      :filterConfig="filterConfig"
      @openChargeDialog="openChargeDialog"
      @handleSearch="handleSearch"
      @changeProject="changeProject"
      @changeCharge="changeCharge"
      @print="handlePrint"
      @export="handleExport"
    ></Wfilter>
    <Wtable
      :columns="columns"
      :pagination="pagination"
      :tableData="tableData"
      @currentChange="currentChange"
      @info="openDetailDialog"
      @update="openChargeDialog"
      @sizeChange="sizeChange"
    >
      <template #creater="{ row }">
        <span>{{ row.creater.realname }}</span>
      </template>
      <template #status="{ row }">
        <el-switch
          v-model="row.status"
          @click="changeStatus(row)"
          inline-prompt
          active-text="启用"
          inactive-text="关闭"
          :disabled="canChangeStatus == 0 ? true : false"
        >
        </el-switch>
      </template>
    </Wtable>
    <Wdialog
      ref="chargeDialog"
      :title="chargeTitle"
      width="50%"
      @wConfirm="handleSure"
    >
      <el-form
        ref="chargeFormRef"
        :model="chargeFormData"
        :rules="chargeFormRules"
        label-position="top"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="name" label="模板名称">
              <el-input
                v-model="chargeFormData.name"
                autocomplete="off"
                placeholder="请输入模板名称"
                clearable
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="charge_type" label="收费模式">
              <el-select
                v-model="chargeFormData.charge_type"
                placeholder="请选择收费模式"
              >
                <el-option
                  v-for="item in chargeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="hour_num" label="有效时间">
              <el-input
                v-model="chargeFormData.hour_num"
                autocomplete="off"
                placeholder="请输入有效时间"
                clearable
                type="number"
                min="0"
              />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item prop="money" label="收费金额">
              <el-input
                type="number"
                min="0"
                v-model="chargeFormData.money"
                autocomplete="off"
                placeholder="请输入收费金额"
                clearable
              /> </el-form-item
          ></el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="pass_type" label="通行模式">
              <el-select
                v-model="chargeFormData.pass_type"
                placeholder="请选择通行模式"
              >
                <el-option
                  v-for="item in trafficModeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="pass_num" label="通行次数">
              <el-input
                type="number"
                min="0"
                v-model="chargeFormData.pass_num"
                autocomplete="off"
                placeholder="请输入通行次数"
                clearable
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="desc" label="缴费说明">
              <div ref="editorElem"></div>
              <!-- <el-input
                type="textarea"
                rows="4"
                v-model="chargeFormData.desc"
                autocomplete="off"
                placeholder="请输入缴费说明"
                clearable
              /> -->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </Wdialog>
    <Wdialog
      ref="detailDialog"
      title="应用详情"
      width="50%"
      @wConfirm="handleApply"
      class="multiple-dialog"
      :showAction="canEditDetail == 1 ? true : false"
    >
      <div class="multiple-content">
        <div
          v-for="(el, index) in detailData"
          :key="index"
          class="toplevel-container"
        >
          <div class="toplevel-title">
            {{ el.name }}
          </div>
          <div class="sublevel-container">
            <div
              v-for="(el2, index2) in el.lowerDepartment"
              :key="index2"
              class="sublevel-item text-hidden"
              :class="
                selectProjectIds.includes(el2.id) ? 'sublevel-active' : ''
              "
              @click="handleSelectProject(el2)"
            >
              {{ el2.name }}
            </div>
          </div>
        </div>
      </div>
    </Wdialog>
  </div>
</template>
<script>
import { ref, onMounted, reactive, nextTick, onUnmounted, computed } from "vue";
import Editor from "wangeditor";
import { useStore } from "vuex";
import {
  print,
  exportExcel,
  getTopBtnAuth,
  getBtnAuth,
} from "@/utils/common.js";
import { ElMessage } from "element-plus";
import Wtable from "@/components/wTable/wTable.vue";
import Wdialog from "@/components/wDialog/wDialog.vue";
import Wfilter from "@/components/wFilter/wFilter.vue";
import ContentTitle from "@/components/contentTitle/contentTitle.vue";
import API from "@/plugins/api.js";
import config from "./charge.json";
import * as Qiniu from "qiniu-js";

export default {
  components: {
    Wtable,
    Wdialog,
    Wfilter,
    ContentTitle,
  },
  setup() {
    const columns = reactive(config.tableConfig.list);
    const pagination = config.tableConfig.pagination;
    const filterConfig = reactive(config.filterConfig);
    const defaultBtns = reactive(config.tableConfig.btns);
    const tableData = ref([]);
    const page = ref(1);
    const keyword = ref("");
    const projectId = ref("");
    const chargeId = ref("");
    const tableTitle = ref("收费模板");
    const store = useStore();
    const rightBtn = computed(() => store.state.saasConfig.rightBtn); //右侧页面的所有权限信息
    // 获取权限按钮
    columns[columns.length - 1].btns = getBtnAuth(rightBtn.value, defaultBtns);
    filterConfig.btns = getTopBtnAuth(rightBtn.value, filterConfig.btns);
    const canChangeStatus = ref(0);
    const canEditDetail = ref(0);
    // 获取权限按钮

    if (rightBtn.value && rightBtn.value.length) {
      rightBtn.value.forEach((el) => {
        if (el.api_menu_token == "Btn_0lpxyJ2EAx1kWvSSLQblDuqzoFJw") {
          //修改状态
          canChangeStatus.value = 1;
        } else if (el.api_menu_token == "Btn_bNiXYnbAiSdBdkj3BYo2Fry9BBvV") {
          // 编辑详情
          canEditDetail.value = 1;
        }
      });
    }
    function fetchData(parmas) {
      let lastParmas = {
        ...parmas,
        page: page.value,
        keywords: keyword.value,
        limit: pagination.pageSize,
        p_id: projectId.value,
        charge_type: chargeId.value,
      };
      -API.getChargeTemplate(lastParmas).then((res) => {
        if (res.data.length) {
          res.data.forEach((el) => {
            el.createrName = el.creater.realname ? el.creater.realname : "";
            if (el.status == 1) {
              el.status = true;
              el.statusStr = "启用";
            } else {
              el.status = false;
              el.statusStr = "未启用";
            }
          });
        }
        pagination.total = res.total;
        tableData.value = res.data;
      });
    }
    const chargeTypeOptions = ref([]);
    const trafficModeOptions = ref([]);
    const qiniuInfo = ref({});
    onMounted(() => {
      fetchData();
      API.getProjectOptions().then((res) => {
        if (res.length) {
          filterConfig.selects[0].mapData = res;
        }
      });
      API.getQiniuToken({}).then((res) => {
        qiniuInfo.value = res;
      });
      API.getTrafficModeOptions().then((res) => {
        for (let key in res) {
          trafficModeOptions.value.push({
            label: res[key],
            value: key,
          });
        }
      });
      API.getChargeTypeOptions().then((res) => {
        for (let key in res) {
          chargeTypeOptions.value.push({
            name: res[key],
            id: key,
          });
        }
        filterConfig.selects[1].mapData = chargeTypeOptions.value;
      });
    });
    function changeStatus(data) {
      if (canChangeStatus.value) {
        const status = data.status == true ? 1 : 0;
        API.changeChargeTemplateStatus({
          id: data.id,
          status: status,
        }).then(() => {
          ElMessage.success({
            message: "操作成功！",
          });
          fetchData();
        });
      } else {
        ElMessage.warning({
          message: "暂无权限操作！",
        });
        return false;
      }
    }
    const currentChange = (v) => {
      page.value = v;
      fetchData();
    };
    const sizeChange = (v) => {
      pagination.pageSize = v;
      fetchData();
    };
    const changeProject = (pro) => {
      projectId.value = pro.value;
      fetchData();
    };
    const handleSearch = (kwd) => {
      keyword.value = kwd;
      fetchData();
    };
    function changeCharge(data) {
      chargeId.value = data.value;
      fetchData();
    }

    const chargeDialog = ref(null);
    const chargeFormRef = ref(null);
    const chargeFormRules = {
      name: [
        { required: true, message: "请输入收费模板名称", trigger: "blur" },
      ],
      charge_type: [
        { required: true, message: "请选择收费模式", trigger: "change" },
      ],
      money: [{ required: true, message: "请输入收费金额", trigger: "blur" }],
      pass_type: [
        { required: true, message: "请选择通行模式", trigger: "change" },
      ],
      pass_num: [
        { required: true, message: "请选择通行次数", trigger: "change" },
      ],
    };
    const chargeFormData = ref({
      name: "",
      charge_type: "",
      money: "",
      hour_num: "",
      pass_type: "",
      pass_num: "",
      desc: "",
      image: [],
    });
    const editor = ref(null);
    const editorElem = ref(null);

    function editorInit() {
      editor.value = new Editor(editorElem.value);
      // 配置 onchange 回调函数

      editor.value.config.onchange = (newHtml) => {
        chargeFormData.value.desc = newHtml;
      };
      // // 自定义菜单配置
      editor.value.config.menus = [
        "head",
        "bold",
        "fontSize",
        "fontName",
        "image",
        "italic",
        "underline",
        "strikeThrough",
        "indent",
        "lineHeight",
        "foreColor",
        "justify",
        "quote",
        "splitLine",
        "undo",
        "redo",
      ];
      // 限制上传图片格式
      editor.value.config.uploadImgAccept = [
        "jpg",
        "jpeg",
        "png",
        "gif",
        "bmp",
      ];
      editor.value.config.qiniu = true;
      editor.value.config.showLinkImg = false;
      // 开启本地上传图片(这是后端上传链接)
      const upToken = qiniuInfo.value.uptoken;
      const domain = qiniuInfo.value.domain;

      editor.value.config.customUploadImg = (file) => {
        if (file.length) {
          file.forEach((el) => {
            const myDate = new Date();
            const year_month = myDate.toLocaleDateString().replace(/\//g, ""); //当前时间日期
            const timeData = Date.parse(new Date()); //当前时间时时间戳
            const random = Math.floor(Math.random() * (1 - 1000) + 1000); //1-1000随机数
            const randoms = Math.floor(Math.random() * (1 - 100) + 100); //1-100随机数
            const filename = el.name;
            const index = filename.lastIndexOf(".");
            const suffix = filename.substr(index + 1);
            const key = `carnonmotor-${year_month}-${timeData}${random}-${randoms}.${suffix}`;
            const config = {
              // useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
              cdnUphost: domain,
            };
            const putExtra = {
              fname: "", //文件原文件名
              params: {}, //用来放置自定义变量
              mimeType: null, //用来限制上传文件类型，为 null 时表示不对文件类型限制；限制类型放到数组里： ["image/png", "image/jpeg", "image/gif"]
            };
            const observable = Qiniu.upload(el, key, upToken, putExtra, config);
            observable.subscribe({
              error: () => {},
              complete: (res) => {
                const sourceLink = domain + res.key;
                editor.value.cmd.do(
                  "insertHtml",
                  '<img src="' + sourceLink + '" style="max-width:100%;"/>'
                );
                chargeFormData.value.image.push({
                  filename: filename,
                  qiniu_key: res.key,
                  ext: res.type,
                  size: el.size,
                });
              },
            });
          });
        }
      };

      editor.value.config.height = 200;

      editor.value.create(); // 创建富文本实例
      if (chargeFormData.value.desc) {
        editor.value.txt.html(chargeFormData.value.desc);
      }
    }
    const chargeTitle = ref("");
    function openChargeDialog(data) {
      if (data && data.row) {
        // 编辑
        chargeTitle.value = "编辑收费模板";
        API.getChargeTemplateDetail({ id: data.row.id }).then((res) => {
          res.image = res.file;
          chargeFormData.value = res;
          chargeDialog.value.show();
          nextTick(() => {
            editorInit();
          });
        });
      } else {
        // 新增
        chargeTitle.value = "新增收费模板";
        chargeFormData.value = {
          name: "",
          charge_type: "",
          money: "",
          hour_num: 24,
          pass_type: "",
          pass_num: "",
          desc: "",
          image: [],
        };
        chargeDialog.value.show();
        nextTick(() => {
          editorInit();
        });
      }
    }
    function handleSure() {
      if (chargeFormRef.value) {
        chargeFormRef.value.validate((valid) => {
          if (valid) {
            if (chargeFormData.value.id) {
              // 编辑
              const lastParmas = {
                id: chargeFormData.value.id,
                name: chargeFormData.value.name,
                charge_type: chargeFormData.value.charge_type,
                money: chargeFormData.value.money,
                hour_num: chargeFormData.value.hour_num,
                pass_type: chargeFormData.value.pass_type,
                pass_num: chargeFormData.value.pass_num,
                desc: chargeFormData.value.desc,
                image: chargeFormData.value.image,
              };
              API.editChargeTemplate(lastParmas).then(() => {
                ElMessage.success({
                  message: "操作成功！",
                });
                chargeDialog.value.close();
                fetchData();
              });
            } else {
              // 新增
              API.addChargeTemplate(chargeFormData.value).then(() => {
                ElMessage.success({
                  message: "操作成功！",
                });
                chargeDialog.value.close();
                fetchData();
              });
            }
          }
        });
      }
    }

    const detailDialog = ref(null);
    const detailData = ref([]);
    const selectProjectIds = ref([]);
    const chargeTemplateId = ref("");
    function openDetailDialog(data) {
      chargeTemplateId.value = data.row.id;
      API.getProjectByChargeTemplate({ t_id: data.row.id }).then((res) => {
        if (res.length) {
          selectProjectIds.value = [];
          detailData.value = res;
          res.forEach((el) => {
            if (el.lowerDepartment.length) {
              el.lowerDepartment.forEach((el2) => {
                if (el2.checked) {
                  selectProjectIds.value.push(el2.id);
                }
              });
            }
          });
          detailDialog.value.show();
        }
      });
    }
    function handleSelectProject(data) {
      const index = selectProjectIds.value.findIndex((item) => item == data.id);
      index != -1
        ? selectProjectIds.value.splice(index, 1)
        : selectProjectIds.value.splice(
            selectProjectIds.value.length - 1,
            0,
            data.id
          );
    }
    function handleApply() {
      API.editProjectByChargeTemplate({
        p_ids: selectProjectIds.value,
        t_id: chargeTemplateId.value,
      }).then(() => {
        ElMessage.success({
          message: "操作成功！",
        });
        detailDialog.value.close();
        fetchData();
      });
    }
    const propertiesConfig = ref([
      { field: "name", displayName: "收费模式" },
      { field: "charge_type_text", displayName: "收费标准" },
      { field: "createrName", displayName: "添加人" },
      { field: "create_time", displayName: "添加时间" },
      { field: "statusStr", displayName: "状态" },
    ]);
    function handlePrint() {
      print(tableTitle.value, tableData.value, propertiesConfig.value);
    }
    function handleExport() {
      try {
        exportExcel(tableTitle.value, tableData.value, propertiesConfig.value);
        ElMessage.success({
          message: "导出成功！",
        });
      } catch (e) {
        ElMessage.error({
          message: "导出失败！",
        });
      }
    }
    onUnmounted(() => {
      filterConfig.selects[0].value = "";
      filterConfig.selects[1].value = "";
    });
    return {
      tableData,
      columns,
      pagination,
      currentChange,
      chargeDialog,
      handleSure,
      chargeFormData,
      chargeFormRules,
      chargeFormRef,
      openChargeDialog,
      trafficModeOptions,
      filterConfig,
      changeProject,
      handleSearch,
      changeCharge,
      openDetailDialog,
      detailDialog,
      handleApply,
      detailData,
      selectProjectIds,
      handleSelectProject,
      chargeTitle,
      sizeChange,
      chargeTypeOptions,
      changeStatus,
      editorInit,
      editorElem,
      handlePrint,
      handleExport,
      tableTitle,
      canChangeStatus,
      canEditDetail,
    };
  },
};
</script>

<style lang='scss'>
.charge-container {
  .header-action {
    .el-input__inner {
      background-color: var(--search-bg-color) !important;
    }
    ::-webkit-input-placeholder {
      color: var(--theme-color);
    }
  }
  .w-e-text-container {
    .placeholder {
      display: none;
    }
  }
}
</style>